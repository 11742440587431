import {DateTime} from 'luxon';
import type {BillerSlug, Gateways, PaymentMethodType} from '.';
import {SurchargeCalculator} from '../util/SurchargeCalculator';
import {
  GatewayForType,
  getAccountTypesInfo,
  getPaymentOptions,
} from './handlers';
import {
  AdminCreatedPlans,
  BillerConfigSchema,
  IframeCardType,
  SetupChargeTypes as SetupChargeType,
} from './schema';

type AccountProps = {
  amountOwing: number;
  nextInstalmentAmount: number;
  setupBreakdown: {
    amount: number;
  }[];
};
const ACCOUNT_META_DATE_FORMAT = 'dd-MM-yyyy';
const DEFAULT_ADMIN_CREATED_PLANS: AdminCreatedPlans = {
  flexibleFixed: {
    enabled: true,
  },
  flexible: {
    enabled: true,
  },
  autoPay: {
    enabled: true,
  },
};

const TEMPLATE_FUNCTIONS = {
  dateShort: (date: string) => {
    return DateTime.fromFormat(date, ACCOUNT_META_DATE_FORMAT).toFormat(
      'dd MMM yyyy'
    );
  },
};

export function replaceTemplateString(
  template: string,
  variables: Record<string, unknown>
) {
  return template.replaceAll(/\{\{[a-zA-Z]+(:[a-zA-Z-]+)?\}\}/g, value => {
    const statement = value.replace('{{', '').replace('}}', '');
    const [variable, ...functionNames] = statement.split(':');

    let variableValue = variables[variable as keyof typeof variables];
    if (variableValue === undefined) {
      return 'Unknown';
    }

    for (const functionName of functionNames) {
      const fn =
        TEMPLATE_FUNCTIONS[functionName as keyof typeof TEMPLATE_FUNCTIONS];
      if (fn === undefined) {
        throw new Error(`Unknown function ${functionName}`);
      }
      variableValue = fn(variableValue as any);
    }
    return typeof variableValue === 'string' ? variableValue : 'Unknown';
  });
}

export class BillerConfig {
  private config: BillerConfigSchema;
  private slug: BillerSlug;

  constructor(
    config: BillerConfigSchema,
    slug: BillerSlug,
    /**
     * Do not use this stage to drive critical functionality. The frontend has
     * no concept of "stage" and cannot reliably provide it.
     */
    private stage = 'production'
  ) {
    this.config = config;
    this.slug = slug;
  }

  get smsProvider() {
    return this.config.smsProvider;
  }

  get emailProvider() {
    return this.config.emailProvider;
  }

  get timezone() {
    return this.config.timezone;
  }

  get currency() {
    return this.config.currency;
  }

  get region() {
    return this.config.region;
  }

  get mobileNumberPlaceholder() {
    switch (this.region) {
      case 'AU':
        return '0400 000 000';
      case 'NZ':
        return '0200 000 000';
      default: {
        throw new Error(`Unknown region ${this.region}`);
      }
    }
  }

  get accountsExemptFromCollectionWhenArrearsOver() {
    return this.config.accountsExemptFromCollectionWhenArrearsOver ?? null;
  }

  get billerSlug() {
    return this.slug;
  }

  get billerId() {
    return this.config.id;
  }

  get hasYearlyRollOver() {
    return !!this.config.hasYearlyRollover;
  }
  get isLive() {
    return this.config.isLive;
  }

  get smsSenderId() {
    return this.config.smsSenderId;
  }

  get accountCategoryLabel() {
    return this.config.accountCategoryLabel ?? 'Notice type';
  }

  get exports() {
    return this.config.exports;
  }

  get remitter() {
    return this.config.remitter ?? null;
  }

  getAccountTypeVerifyAccountDetails(accountType: string) {
    return (
      this.config.verifyAccountDetails?.[accountType] ??
      this.config.verifyAccountDetails?.default ??
      null
    );
  }

  getAccountTypeVerificationCodeSource(accountType: string) {
    return (
      this.config.accountVerificationCodeSource?.[accountType] ??
      'last four digits of your payment reference'
    );
  }

  get canStaffProcessImports() {
    return this.config.imports?.allowNonStaffProcessing ?? false;
  }

  get support() {
    return this.config.support ?? null;
  }

  get surchargeCalculator() {
    return new SurchargeCalculator(this.config.surcharges ?? []);
  }

  get paymentMethods() {
    return this.config.paymentMethods;
  }

  get canPayZeroBalanceAccount() {
    return this.config.canPayZeroBalanceAccount ?? false;
  }

  get privacyWarning() {
    return this.config.privacyWarning;
  }

  get hasRequestPlan() {
    return this.config.requestedPlans.enabled;
  }

  get extendPlanTerms() {
    return this.config.payEveryX?.extendPlanTerms ?? null;
  }

  get requestedPlanAvailableAsAlternativePlan() {
    return this.config.requestedPlans.availableAsAlternativePlan;
  }

  get requestPlanTerms() {
    return this.config.requestedPlans?.terms ?? null;
  }

  get hasCatchUpPlans() {
    return this.config.catchUpPlans.enabled;
  }

  get catchUpPlanTerms() {
    return this.config.catchUpPlans?.terms ?? null;
  }

  get catchupInterest() {
    return this.config.catchUpPlans?.interest;
  }

  get catchupShowArrangementText() {
    return this.config.catchUpPlans?.showArrangementText ?? null;
  }

  get requirePaymentNotificationConsent() {
    return this.config.paymentMethodAgreement?.smsNotificationConsent ?? false;
  }

  get allowAuthorityToOperateNominatedAccount() {
    return (
      this.config.nominatedAccountAgreement.allowAuthorityToOperate ?? false
    );
  }

  get requiresNominatedAccountAgreement() {
    return this.config.nominatedAccountAgreement.required;
  }

  get nominatedAccountAgreementTerms() {
    return this.config.nominatedAccountAgreement?.terms ?? null;
  }

  get acceptedCardTypes(): IframeCardType[] {
    return this.config.acceptedCardTypes ?? ['visa', 'masterCard'];
  }

  get calculatedInstalments() {
    return this.config.calculatedInstalments ?? null;
  }

  get accountWhitelist() {
    return this.config.accountWhitelist;
  }

  catchUpPlanBillingPeriods(accountType: string) {
    return this.config.catchUpPlans?.billingPeriods?.[accountType] ?? null;
  }

  get features() {
    return this.config.featureConfig;
  }

  get noticeOnInterest() {
    return this.config?.noticeOnInterest;
  }

  get infringements() {
    return this.config.infringements;
  }

  get accountLookupAgreement() {
    return this.config?.accountLookupAgreement ?? [];
  }

  get adminCreatedPlans(): AdminCreatedPlans {
    return this.config.adminCreatedPlans ?? DEFAULT_ADMIN_CREATED_PLANS;
  }

  get directDebitUserId() {
    return this.config?.directDebitUserId;
  }

  paymentMethodsForAccountType(type: string) {
    return (
      Object.entries(this.config.paymentMethodForAccountType ?? {}).find(
        ([typeInConfig, __]) => typeInConfig === type
      )?.[1] ?? this.config.paymentMethods
    );
  }

  makeCMSURL(asset: 'logo.png' | 'payble-logo.png') {
    if (asset === 'payble-logo.png') {
      const url = new URL(this.config.consumerBaseURL);
      url.pathname = `cms/${asset}`;
      return url.toString();
    }
    const url = new URL(this.config.consumerBaseURL);
    url.pathname = `cms/biller/${this.slug}/${asset}`;
    return url.toString();
  }

  makeConsumerURL(args: {path?: string; query?: Record<string, string>} = {}) {
    const url =
      this.stage === 'production'
        ? new URL(this.config.consumerBaseURL)
        : new URL(`https://app-${this.stage}.payble.com.au`);

    if (args.path) {
      const path = args.path.startsWith('/') ? args.path.slice(1) : args.path;
      url.pathname += `biller/${this.slug}/${path}`;
    } else {
      url.pathname = `biller/${this.slug}`;
    }

    if (args.query) {
      for (const [key, value] of Object.entries(args.query)) {
        url.searchParams.set(key, value);
      }
    }

    return url.toString();
  }

  getBpointBillerCode(accountType: string) {
    return this.config.bpointBillerCode?.[accountType] ?? null;
  }

  get allGateways() {
    const values = new Set(
      Object.values(this.config.gateways).filter((v): v is Gateways => !!v)
    );
    return Array.from(values);
  }

  gatewayForPaymentMethod<T extends PaymentMethodType>(
    paymentMethod: T
  ): GatewayForType<T> | Error {
    const gateway = this.config.gateways[paymentMethod];
    if (!gateway) {
      return new Error(`Gateway for ${paymentMethod} not configured`);
    }
    return gateway as GatewayForType<T>;
  }

  shouldChargeSetupFor(key: string): boolean {
    return !!this.config.setupCharges?.[
      key as keyof BillerConfigSchema['setupCharges']
    ];
  }

  formatChargeBreakdown(breakdown: {type: string; value: number}[]) {
    const defaultChargeFormatter = {
      arrears: 'Arrears - Due now',
      fines: 'Fines - Due now',
      legal_fees: 'Legal Fees - Due now',
      milestone: 'Instalment - {{date:dateShort}}',
    };
    return breakdown.map(({value, ...charge}) => {
      const type = charge.type as SetupChargeType;
      const chargeTemplate =
        this.config.setupCharges?.[type]?.labelTemplate ??
        defaultChargeFormatter[type] ??
        'Unknown';

      return {
        amount: value,
        label: replaceTemplateString(chargeTemplate, charge as any),
      };
    });
  }

  getAccountPaymentConfig(args: AccountProps) {
    if (!this.config.oneOffPayment) {
      throw new Error('One off payments are not enabled');
    }

    const nextInstalment = args.nextInstalmentAmount ?? 0;
    const arrears = args.setupBreakdown.reduce(
      (sum, b) => Math.max(b.amount, 0) + sum,
      0
    );
    const amountOwing = args.amountOwing ?? 0;

    const __ = (__: never): never => {
      throw new Error();
    };

    let result: {
      minAmount: number;
      maxAmount: number;
      defaultAmount: number;
    };

    switch (this.config.oneOffPayment.type) {
      case 'amount-owing':
        result = {
          minAmount: amountOwing,
          maxAmount: amountOwing,
          defaultAmount: amountOwing,
        };
        break;
      case 'any-amount':
        result = {
          minAmount:
            amountOwing > 0
              ? Math.min(
                  this.config.featureConfig.ONE_OFF_AMOUNT_MIN,
                  amountOwing
                )
              : this.config.featureConfig.ONE_OFF_AMOUNT_MIN,
          maxAmount: this.config.featureConfig.ONE_OFF_AMOUNT_MAX,
          defaultAmount: amountOwing,
        };
        break;
      case 'max-account-balance':
        result = {
          minAmount: this.config.featureConfig.ONE_OFF_AMOUNT_MIN,
          maxAmount: args.amountOwing,
          defaultAmount: args.amountOwing,
        };
        break;
      case 'next-instalment-arrears':
        result = {
          minAmount: nextInstalment + arrears,
          maxAmount: args.amountOwing,
          defaultAmount: nextInstalment + arrears,
        };
        break;
      case 'any-amount-default-upcoming':
        result = {
          minAmount: this.config.featureConfig.ONE_OFF_AMOUNT_MIN,
          maxAmount: this.config.featureConfig.ONE_OFF_AMOUNT_MAX,
          defaultAmount: nextInstalment + arrears,
        };
        break;
      default:
        __(this.config.oneOffPayment.type);
        throw new Error('Switch must be exhausted');
    }

    if (result.minAmount <= 0) {
      result.minAmount = 1000;
    }

    if (result.maxAmount <= result.minAmount) {
      result.maxAmount = result.minAmount;
    }

    if (
      result.defaultAmount < result.minAmount ||
      result.defaultAmount > result.maxAmount
    ) {
      result.defaultAmount = result.minAmount;
    }

    return {
      ...result,
      lockedAmount: result.minAmount === result.maxAmount,
    };
  }

  getExternalIdLabel(accountType: string) {
    if (accountType === 'infringements') {
      return 'Infringement number';
    }
    return this.config.externalIdLabel ?? 'Property number';
  }

  getAutopayConfig(accountType: string) {
    if (!this.config.autopay) {
      throw new Error('Autopay is not enabled');
    }

    if (
      !this.config.autopay.automatic.includes(accountType) &&
      !this.config.autopay.annually.includes(accountType)
    ) {
      throw new Error(
        `Account type ${accountType} is not configured to use autopay`
      );
    }

    return {
      automatic: this.config.autopay.automatic.includes(accountType),
      annually: this.config.autopay.annually.includes(accountType),
    };
  }

  getDefaultInstalmentPlanTargetMonthAndDayForBiller() {
    if (!this.features.PAY_EVERY_X) {
      return undefined;
    }

    const date = this.config?.payEveryX?.targetDate ?? {month: 5, day: 31};

    return date;
  }

  getPaymentOptions(accountType: string, hasMilestones: boolean) {
    const featureConfig = this.features;
    return getPaymentOptions({
      featureConfig,
      billerSlug: this.slug,
      accountType,
      hasMilestones,
    });
  }
  getAccountTypesInfo() {
    return getAccountTypesInfo(this.slug);
  }

  getAccountTypeTitle(accountType: string) {
    const info = this.getAccountTypesInfo().find(({id}) => id === accountType);
    // This shouldn't be required but prevent billers from breaking when an
    // account type is removed but still present in a portal somewhere
    return (info?.title ?? accountType).toLowerCase();
  }

  normalizeInboundExternalId(externalId: string) {
    if (!this.config.inboundAccountExternalIdNormalization) {
      return externalId;
    }
    const {type, character, length} =
      this.config.inboundAccountExternalIdNormalization;
    if (type === 'pad_start') {
      return externalId.padStart(length, character);
    } else {
      throw new Error(`Unknown normalization type ${type}`);
    }
  }

  get supportEmail() {
    return this.config.support?.email || 'support@payble.com.au';
  }

  get autopayTargetDates() {
    return this.config.autopayTargetDates ?? [];
  }
}
